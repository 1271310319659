import React from "react"
import {Link} from "@reach/router";
import {
    FaUser,
  } from "react-icons/fa"


import Logo from "../images/GS1_Mongolia_61px_Tall_RGB_2014-12-17.png"
  
export default () => (
  
    <div className="header-top">
      <div className="container">
        <div className="logo">
          <div className="logo1">
            <a href="/">
              <img alt="GS1 logo" src={Logo} />
            </a>

            <p className="large hidden-xs hidden-sm">
              <strong style={{fontWeight:"bold"}}>
                  Welcome to GS1 Mongolia
              </strong>
              <br />
              The Global Language of Business</p>
          </div>
        </div>
        <div className="secondary-nav">
          <ul className="list-inline">
            <li><Link to="/asuult">Асуулт Хариулт</Link></li>
            <li><Link to="/location"> &nbsp;Холбоо барих</Link></li>
            <li><Link to="/contact"> &nbsp;Санал хүсэлт</Link></li>
            <li><a href="https://gs1.org"> &nbsp;English</a></li>
          </ul>
          <a
            href="http://members.gs1mongolia.org"
            className="btn-primary login-button bg-blue" role="button"
          >
              <span className="icon-user"></span>
            Гишүүдийн булан
          </a>
            <a
                href="http://app.gs1mongolia.org/company-self-create"
                className="btn-primary login-button"
                role="button"
            >
              <span className="icon-user"></span>
                Шинээр Баркод Авах
            </a>
        </div>
      </div>
    </div>
  )

