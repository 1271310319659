import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {Link} from "@reach/router";

import Nav from "react-bootstrap/Nav";
import "../styles/home.scss"
import Logo from "../images/GS1_Mongolia_61px_Tall_RGB_2014-12-17.png";
import juram from "../docs/juram.pdf"
import memberDoc from "../docs/member.pdf"
import memberIndividualDoc from "../docs/member-ind.pdf"
import productDoc from "../docs/product.pdf"
import feesDoc from "../docs/fees.pdf"
import glnDoc from "../docs/gln.pdf"
import ssccDoc from "../docs/sscc.pdf"
import albanDoc from "../docs/alban-template.docx"

const Navigation = ({apageprop, first}) => {
    const [mouseOverAboutButton, setMouseOverAboutButton] = useState(false)
    const [mouseOverAboutMenu, setMouseOverAboutMenu] = useState(false)
    const [mouseOverServiceButton, setMouseOverServiceButton] = useState(false)
    const [mouseOverServiceMenu, setMouseOverServiceMenu] = useState(false)
    const [mouseOverNewsButton, setMouseOverNewsButton] = useState(false)
    const [mouseOverNewsMenu, setMouseOverNewsMenu] = useState(false)
    const [mouseOverMembershipButton, setMouseOverMembershipButton] = useState(false)
    const [mouseOverMembershipMenu, setMouseOverMembershipMenu] = useState(false)
    const [mouseOverStandardsButton, setMouseOverStandardsButton] = useState(false)
    const [mouseOverStandardsMenu, setMouseOverStandardsMenu] = useState(false)
    const [mouseOverProjectsButton, setMouseOverProjectsButton] = useState(false)
    const [mouseOverProjectsMenu, setMouseOverProjectsMenu] = useState(false)


    const [showNavbar, setShowNavbar] = useState(false)
    const [showServiceDropDown, setShowServiceDropDown] = useState(false)
    const [showNewsDropDown, setShowNewsDropDown] = useState(false)
    const [showMembershipDropDown, setShowMembershipDropDown] = useState(false)
    const [showStandardsDropDown, setShowStandardsDropDown] = useState(false)

    const [activeAbout, setActiveAbout] = useState(false)
    const [activeService, setActiveService] = useState(false)
    const [activeNews, setActiveNews] = useState(false)
    const [activeMembership, setActiveMembership] = useState(false)
    const [activeStandards, setActiveStandards] = useState(false)
    const [activeProjects, setActiveProjects] = useState(false)

    const timeoutLength = 300;
    const openAbout = mouseOverAboutButton || mouseOverAboutMenu;
    const openService = mouseOverServiceButton || mouseOverServiceMenu;
    const openNews = mouseOverNewsButton || mouseOverNewsMenu;
    const openMembership = mouseOverMembershipButton || mouseOverMembershipMenu;
    const openStandards = mouseOverStandardsButton || mouseOverStandardsMenu;
    const openProjects = mouseOverProjectsButton || mouseOverProjectsMenu;


    const handleEnterAboutButton = e => {
        setMouseOverAboutButton(true)
    }
    const handleLeaveAboutButton = e => {
        setTimeout(() => {
            setMouseOverAboutButton(false)
        }, timeoutLength);
    }
    const handleEnterAboutMenu = e => {
        setMouseOverAboutMenu(true)
    }
    const handleLeaveAboutMenu = e => {
        setTimeout(() => {
            setMouseOverAboutMenu(false)
        }, timeoutLength);
    }


    const handleEnterServiceButton = e => {
        setMouseOverServiceButton(true)
    };
    const handleLeaveServiceButton = e => {
        setTimeout(() => {
            setMouseOverServiceButton(false)
        }, timeoutLength);
    };
    const handleEnterServiceMenu = e => {
        setMouseOverServiceMenu(true)
    };
    const handleLeaveServiceMenu = e => {
        setTimeout(() => {
            setMouseOverServiceMenu(false)
        }, timeoutLength);
    };

    const handleEnterNewsButton = e => {
        setMouseOverNewsButton(true)
    };
    const handleLeaveNewsButton = e => {
        setTimeout(() => {
            setMouseOverNewsButton(false)
        }, timeoutLength);
    };
    const handleEnterNewsMenu = e => {
        setMouseOverNewsMenu(true)
    };
    const handleLeaveNewsMenu = e => {
        setTimeout(() => {
            setMouseOverNewsMenu(false)
        }, timeoutLength);
    };

    const handleEnterMembershipButton = e => {
        setMouseOverMembershipButton(true)
    };
    const handleLeaveMembershipButton = e => {
        setTimeout(() => {
            setMouseOverMembershipButton(false)
        }, timeoutLength);
    };
    const handleEnterMembershipMenu = e => {
        setMouseOverMembershipMenu(true)
    };
    const handleLeaveMembershipMenu = e => {
        setTimeout(() => {
            setMouseOverMembershipMenu(false)
        }, timeoutLength);
    };

    const handleEnterStandardsButton = e => {
        setMouseOverStandardsButton(true)
    };
    const handleLeaveStandardsButton = e => {
        setTimeout(() => {
            setMouseOverStandardsButton(false)
        }, timeoutLength);
    };
    const handleEnterStandardsMenu = e => {
        setMouseOverStandardsMenu(true)
    };
    const handleLeaveStandardsMenu = e => {
        setTimeout(() => {
            setMouseOverStandardsMenu(false)
        }, timeoutLength);
    };


    const handleEnterProjectsButton = e => {
        setMouseOverProjectsButton(true)
    };
    const handleLeaveProjectsButton = e => {
        setTimeout(() => {
            setMouseOverProjectsButton(false)
        }, timeoutLength);
    };
    const handleEnterProjectsMenu = e => {
        setMouseOverProjectsMenu(true)
    };
    const handleLeaveProjectsMenu = e => {
        setTimeout(() => {
            setMouseOverProjectsMenu(false)
        }, timeoutLength);
    };

    const handleCollapse = e => {
        setShowNavbar(!showNavbar)
    };


    let homeNavbarItemClass = classnames({
        "nav-item": true
    });

    let aboutNavbarItemClass = classnames({
        "nav-item": true,
        "yamm-fw": true,
        "dropdown": true,
        "open": openAbout,
        "active": activeAbout,
    });

    let serviceNavbarItemClass = classnames({
        "yamm-fw": true,
        "dropdown": true,
        "open": openService,
        "active": activeService,
    })

    let newsNavbarItemClass = classnames({
        "yamm-fw": true,
        "dropdown": true,
        "open": openNews,
        "active": activeNews,
    })

    let membershipNavbarItemClass = classnames({
        "yamm-fw": true,
        "dropdown": true,
        "open": openMembership,
        "active": activeMembership,
    })

    let standardsNavbarItemClass = classnames({
        "yamm-fw": true,
        "dropdown": true,
        "open": openStandards,
        "active": activeStandards,
    })

    let projectsNavbarItemClass = classnames({
        "yamm-fw": true,
        "dropdown": true,
        "open": openProjects,
        "active": activeProjects,
    })


    let navbarCollapsibleClass = classnames({
        "navbar-collapse ": true,
        "collapse": true,
        "show": showNavbar,
    })

    useEffect(() => {
        console.log("useEffect called pageprop!", apageprop)
        if (apageprop.startsWith('/ab/') || apageprop.startsWith('/about/')) {
            setActiveAbout(true);
        } else if (apageprop.startsWith('/s/') || apageprop.startsWith('/srv/')  || apageprop.startsWith('/service/') || apageprop.startsWith('/brochure')) {
            setActiveService(true);
        } else if (apageprop.startsWith('/projects/') ) {
            setActiveProjects(true);
        } else if (apageprop.startsWith('/m/') || apageprop.startsWith('/membership/')) {
            setActiveMembership(true);
        } else if (apageprop.startsWith('/standards')) {
            setActiveStandards(true);
        } else if (apageprop.startsWith('/newsletter')
            || apageprop.startsWith('/nl/')
            || apageprop.startsWith('/events')
            || apageprop.startsWith('/e/')
            || apageprop.startsWith('/news')
            || apageprop.startsWith('/n/')) {
            setActiveNews(true);
        }
    }, []);

    return (
        <div className="navigation">
            <div className="container">
                <Nav className="navbar yamm navbar-default" role="navigation">
                    <div className="navbar-header">
                        <div className="logo1 visible-xs"><a href="#0"><img src={Logo} height="61" border="0"
                                                                            alt="GS1 logo"/></a></div>
                        <button type="button" data-toggle="collapse" data-target="#navbar-collapse-example"
                                className="navbar-toggle collapsed" onClick={handleCollapse}>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div>
                            <a className="btn-primary login-button bg-blue" style={{marginRight: "3px"}} role="button"
                               href="http://members.gs1mongolia.org"><span
                                className="icon-user"></span>Гишүүд</a>
                            <a className="btn-primary login-button" role="button"
                               href="http://app.gs1mongolia.org/company-self-create"><span
                                className="icon-user"></span>Элсэх</a>
                        </div>
                    </div>
                    <div id="navbar-collapse-example" className={navbarCollapsibleClass}>
                        <ul className="nav navbar-nav">
                            <li className={aboutNavbarItemClass}>
                                <a className="nav-link dropdown-toggle" onMouseEnter={handleEnterAboutButton}
                                   onMouseLeave={handleLeaveAboutButton}>Бидний Тухай</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterAboutMenu}
                                    onMouseLeave={handleLeaveAboutMenu}>
                                    <li>
                                        <div className="col-sm-4 column visible-lg visible-md visible-sm">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-orange">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/ab/aboutus">Танилцуулга</Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column dropdown-item visible-lg visible-md visible-sm">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-orange">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/about/greeting">Мэндчилгээ</Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column dropdown-item visible-lg visible-md visible-sm">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-orange">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/ab/history">Он цагийн товчоон</Link>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column visible-xs">
                                            <ul className="section">
                                                <li><Link to="/ab/aboutus">Танилцуулга</Link></li>
                                                <li><Link to="/about/greeting">Мэндчилгээ</Link></li>
                                                <li><Link to="/ab/history">Он цагийн товчоон</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li className={serviceNavbarItemClass}>
                                <a className="dropdown-toggle" onMouseEnter={handleEnterServiceButton}
                                   onMouseLeave={handleLeaveServiceButton}>Үйлчилгээ</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterServiceMenu}
                                    onMouseLeave={handleLeaveServiceMenu}>
                                    <li>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/srv/service_gs1app">
                                                            GS1 Mongolia APP
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/srv/service_experts">GS1 experts team</Link>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <Link to="/srv/service_logoplacement">Лого байршуулах санал</Link>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/srv/service_training">Сургалт</Link>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/service/brochure">Ном гарын авлага</Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-blue">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <Link to="/main/main_photo">
                                                            Зураг авах үйлчилгээ</Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </li>

                            <li className={newsNavbarItemClass}>
                                <a className="dropdown-toggle" onMouseEnter={handleEnterNewsButton}
                                   onMouseLeave={handleLeaveNewsButton}>Мэдээ Мэдээлэл</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterNewsMenu}
                                    onMouseLeave={handleLeaveNewsMenu}>
                                    <li>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-sky">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/news">
                                                            Мэдээ, Мэдээлэл
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-sky">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/events">
                                                            Арга Хэмжээ
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-sky">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/newsletter">
                                                            Электрон сонин
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li className={membershipNavbarItemClass}>
                                <a className="dropdown-toggle" onMouseEnter={handleEnterMembershipButton}
                                   onMouseLeave={handleLeaveMembershipButton}>Гишүүнчлэл</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterMembershipMenu}
                                    onMouseLeave={handleLeaveMembershipMenu}>
                                    <li>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={juram}
                                                               download={true} className="navbar-item">Журам</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={memberDoc}
                                                               download={true} className="navbar-item">Байгууллагын
                                                                анкет</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={memberIndividualDoc}
                                                               download={true} className="navbar-item">Хувь хүний анкет
                                                                </a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={productDoc}
                                                               download={true} className="navbar-item">Бүтээгдэхүүний
                                                                анкет</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </li>
                                    <li>
                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={feesDoc}
                                                               download={true} className="navbar-item">Гишүүнчлэлийн
                                                                төлбөр</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={glnDoc}
                                                               download={true} className="navbar-item">Байршлын глобал дугаар авах анкет</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={ssccDoc}
                                                               download={true} className="navbar-item">SSCC  авах анкет</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-4 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-grass">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4>
                                                            <a href={albanDoc}
                                                               download={true} className="navbar-item">Албан бичгийн загвар</a>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li className={standardsNavbarItemClass}>
                                <a className="dropdown-toggle" onMouseEnter={handleEnterStandardsButton}
                                   onMouseLeave={handleLeaveStandardsButton}>Стандартууд</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterStandardsMenu}
                                    onMouseLeave={handleLeaveStandardsMenu}>
                                    <li>
                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/standards/standards">
                                                            GS1 Стандартууд
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>

                                                        <h4><Link to="/standards/laws">
                                                            Хууль, Дурэм, Журмууд
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>

                            <li className={projectsNavbarItemClass}>
                                <a className="dropdown-toggle" onMouseEnter={handleEnterProjectsButton}
                                   onMouseLeave={handleLeaveProjectsButton}>Төсөл, Хөтөлбөр</a>
                                <ul className="dropdown-menu" onMouseEnter={handleEnterProjectsMenu}
                                    onMouseLeave={handleLeaveProjectsMenu}>
                                    <li>
                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_grove">
                                                            GS1 төгөл & GS1 grove
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_categorization">
                                                            Ангилалын кодыг уялдуулах
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_vat">
                                                            НӨАТУС-д GS1-13 стандартыг ашиглах
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_food">
                                                            Хүнсний эрхийн бичиг олгох процессыг цахимжуулах
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_blood">
                                                            Цусан бүтээгдэхүүний бүртгэл, хяналтанд GS1 стандартыг ашиглах
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 column">
                                            <div className="content-module-container">
                                                <div className="content-module-wrapper top-border-peach">
                                                    <div className="content-module"
                                                         style={{padding: "20px", marginBottom: "20px"}}>
                                                        <h4><Link to="/projects/projects_women">
                                                            “Эмэгтэйчүүдийн тогтвортой бизнес ба хүний эрх” төсөл
                                                        </Link></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </Nav>
            </div>
        </div>
    )
}

export default Navigation
