const assetsObject = {
    aboutus: require('./markdown-pages/about/aboutus.md'),
    history: require('./markdown-pages/about/history.md'),
    standards: require('./markdown-pages/standards/index.md'),
    _10steps1: require('./markdown-pages/standards/10steps1.md'),
    _10steps2: require('./markdown-pages/standards/10steps2.md'),
    color: require('./markdown-pages/standards/color.md'),
    laws: require('./markdown-pages/standards/laws.md'),
    barcode_get: require('./markdown-pages/service/Зураасан код авах.md'),
    barcode_print: require('./markdown-pages/service/Зураасан код хэвлэлт.md'),
    rfid: require('./markdown-pages/articles/Бүтээгдэхүүний электрон код.md'),

    service_gs1app: require('./markdown-pages/service/gs1app.md'),
    service_training: require('./markdown-pages/service/gs1training.md'),
    service_logoplacement: require('./markdown-pages/service/logo_placement.md'),
    service_experts: require('./markdown-pages/service/gs1experts.md'),

    projects_grove: require('./markdown-pages/projects/grove.md'),
    projects_categorization: require('./markdown-pages/projects/categorization.md'),
    projects_vat: require('./markdown-pages/projects/vat.md'),
    projects_blood: require('./markdown-pages/projects/blood_product.md'),
    projects_food: require('./markdown-pages/projects/food_talon.md'),
    projects_women: require('./markdown-pages/projects/women.md'),

    main_app: require('./markdown-pages/main/app.md'),
    main_photo: require('./markdown-pages/main/photo.md'),
    main_adv: require('./markdown-pages/main/adv.md'),
}
module.exports = assetsObject