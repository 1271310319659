import React from "react";
import {Link} from "@reach/router";
import "../styles/wordpress.scss"
import juram from "../docs/juram.pdf"
import memberDoc from "../docs/member.pdf"
import productDoc from "../docs/product.pdf"
import feesDoc from "../docs/fees.pdf"
import glnDoc from "../docs/gln.pdf"
import ssccDoc from "../docs/sscc.pdf"
import packageJson from '../../package.json';

export default () => (
    <div>
        <div className="row" >
            <div className="col-xs-12">
                <div className="bg-blue" style={{paddingTop:"5px", paddingBottom:"3px"}}>
                <p className=" text-center color-white" style={{fontWeight:"500", fontSize:"1.3em"}}>GS1 GLOBAL</p>
                </div>
            </div>
        </div>

        <div className="container">
            <footer>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 text-center">
                        <h4 style={{fontWeight:"600", fontSize:"1.3em"}}><span className="color-orange">+</span>10,000,000,000</h4>
                        <p style={{fontWeight:"400", fontSize:"1.0em"}}>Өдөрт <span className="color-orange">10</span> тэрбум удаа баркод уншигддаг</p>
                    </div>
                    <div className="col-xs-12 col-sm-4  text-center">
                        <h4 style={{fontWeight:"600", fontSize:"1.3em"}}><span className="color-orange">+</span>116</h4>
                        <p style={{fontWeight:"400", fontSize:"1.0em"}}>Дэлхийн <span className="color-orange">116</span> улсад гишүүнчлэлтэй</p>
                    </div>
                    <div className="col-xs-12 col-sm-4 text-center">
                        <h4 style={{fontWeight:"600", fontSize:"1.3em"}}><span className="color-orange">+</span>3,000,000</h4>
                        <p style={{fontWeight:"400", fontSize:"1.0em"}}><span className="color-orange">30</span> гаруй салбарын <span className="color-orange">3</span> сая компани</p>
                    </div>

                </div>
            </footer>
        </div>

    </div>
)

