import React, {useEffect, useState} from "react";
import {Location} from "@reach/router";
import HeaderTop from "./headerTop";
import Navigation from "./navigation";
import FooterList from "./footerList";
import {Link}  from "@reach/router"

const Layout = ({ aPageProp, children}) => {

    let f = ""
    useEffect(() => {
        console.log("Navigation useEffect called:" + aPageProp)
        // console.log("Navigation useEffect called:" + getFirstPath(aPageProp))

//    f = getFirstPath(aPageProp);
        //return <Navigation first={f} />
    })

    return (

        <div className="toolkit">
            <header>
                <HeaderTop></HeaderTop>
                <Navigation apageprop={aPageProp} first="news"/>
            </header>
            <div className="container site-content flush-outside">
                <div className="col-sm-3 hidden-xs hidden-sm">
                    <nav className="local-navigation structure-navigation-other">
                        <ul className="list-group">
                            <li className="list-group-item item-l1"><a href="/">GS1 Монгол</a></li>
                            <ul className="list-group group-l2">
                                <li className="list-group-item item-l2"><Link to="/ab/aboutus">Бидний тухай</Link></li>
                                <li className="list-group-item item-l2 item-l2-active"><a href="#0">Асуултууд</a></li>
                                <ul className="list-group group-l3">
                                    <li className="list-group-item item-l3"><Link to="/asuult/байнга-тавигддаг-асуултууд">Байнга тавигддаг</Link></li>
                                    <li className="list-group-item item-l3"><Link to="/asuult/тээвэр-логистик">Тээвэр логистик</Link></li>
                                    <li className="list-group-item item-l3"><Link to="/asuult/gs1-систем">GS1 систем</Link></li>
                                </ul>
                                <li className="list-group-item item-l2">Мэдээ Мэдээлэл</li>
                                <ul className="list-group group-l3">
                                    <li className="list-group-item item-l3"><Link to="/news">Мэдээ Мэдээлэл</Link></li>
                                    <li className="list-group-item item-l3"><Link to="/events">Арга Хэмжээ</Link></li>
                                    <li className="list-group-item item-l3"><Link to="/newsletter">Электрон сонин</Link></li>
                                </ul>
                                <li className="list-group-item item-l2">Үйлчилгээ</li>
                                <ul className="list-group group-l3">
                                    <li className="list-group-item item-l3"><a href="https://app.gs1mongolia.org/company-self-create">Шинээр баркод авах</a></li>
                                    <li className="list-group-item item-l3"><a href="https://members.gs1mongolia.org">Гишүүдийн булан</a></li>
                                    <li className="list-group-item item-l3"><a href="https://www.gs1.org/services/check-digit-calculator">Хяналтын орон</a></li>
                                </ul>
                            </ul>
                        </ul>
                    </nav>
                </div>
                <div className="col-xs-12 col-md-9">
                    {children}
                </div>
            </div>
            <FooterList />
        </div>
    )
}
export default Layout
