const qassetsObject = {
    "байнга-тавигддаг-асуултууд": {
        title:"Байнга тавигддаг асуултууд",
        cat:"faq"
    },
    "глобал-стандартын-менежмент-процесс": {
        title:"Глобал стандартын менежмент процесс",
        cat:"global-standard"
    },

    "зураасан-кодыг-хэрхэн-хэвлэх-вэ": {
        title:"Зураасан кодыг хэрхэн хэвлэх вэ",
        cat:"barcode-print"
    },

    "тээвэр-логистик": {
        title:"Тээвэр логистик",
        cat:"transport"
    },

    "эрүүл-мэнд": {
        title:"Эрүүл мэнд",
        cat:"health"
    },

    "mobile-commerce": {
        title:"Mobile commerce",
        cat:"mobile-commerce"
    },

    "gs1-систем": {
        title:"GS1 систем",
        cat:"gs1-system"
    },

    "gs1-cloud-гэж-юу-вэ": {
        title:"GS1 Cloud гэж юу вэ?",
        cat:"gs1-cloud"
    },

    "gepir": {
        title:"GEPIR",
        cat:"gepir"
    },

    "gdsn-мигс": {
        title:"GDSN-МИГС",
        cat:"gdsn"
    },

    "epc-rfid": {
        title:"EPC-RFID",
        cat:"epc-rfid"
    },

    "ecom-гэж-юу-вэ": {
        title:"eCom гэж юу вэ?",
        cat:"ecom"
    },
    "udi-гэж-юу-вэ": {
        title:"UDI гэж юу вэ?",
        cat:"udi"
    },
}
module.exports = qassetsObject