import React, {useRef, useState, useEffect} from "react";

import AnungooIcon from "../partner-logos/p_anungoo.jpg";
import ApuIcon from "../partner-logos/p_apu.png";
import ArvainUndesIcon from "../partner-logos/p_arvain_undes.png";
import BaigalIcon from "../partner-logos/p_baigal.png";
import CuIcon from "../partner-logos/p_cu.jpg";
import EhlelIcon from "../partner-logos/p_ehlel.jpg";
import EnhiinshuuderIcon from "../partner-logos/p_enkhiin-shuuder.jpg";
import GobiIcon from "../partner-logos/p_gobi.jpg";
import GreenGroupIcon from "../partner-logos/p_green-group.jpg";
import Gs25Icon from "../partner-logos/p_gs25.png";

import LerboralioIcon from "../partner-logos/p_lerbolario.jpg";
import LhamourIcon from "../partner-logos/p_lhamour.jpg";
import McsIcon from "../partner-logos/p_mcs.jpg";
import MobicomIcon from "../partner-logos/p_mobicom.jpg";
import MonkondiIcon from "../partner-logos/p_monkondi.png";
import MonosIcon from "../partner-logos/p_monos-group.jpg";
import MonpelletsIcon from "../partner-logos/p_monpellets.jpg";
import NominIcon from "../partner-logos/p_nomin.jpg";

import SharDoctorIcon from "../partner-logos/p_shar-doctor.jpg";
import SodonFoodsIcon from "../partner-logos/p_sodon_foods.jpg";
import SuuIcon from "../partner-logos/p_suu.jpg";
import TavinUsIcon from "../partner-logos/p_tavin-us.jpg";
import TesoIcon from "../partner-logos/p_teso.jpg";
import TodOimsIcon from "../partner-logos/p_tod-oims.jpg";

import UguujIcon from "../partner-logos/p_uguuj.jpg";
import UnitelIcon from "../partner-logos/p_unitel.jpg";
import UrganaIcon from "../partner-logos/p_urgana.jpg";
import VitafitIcon from "../partner-logos/p_vitafit.png";


import "../styles/footerPartners.css"


const FooterPartners = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDone, setSlideDone] = useState(true);
    const [timeID, setTimeID] = useState(null);
    const [lastPos, setLastPos] = useState(0);


    const sliderRef = useRef(null);
    const scrollAmount = 170;
    const [images, setImages] = useState([

    AnungooIcon,
    ApuIcon,
    ArvainUndesIcon,
    BaigalIcon,
    CuIcon,
    EhlelIcon,
    EnhiinshuuderIcon,
    GobiIcon,
    GreenGroupIcon,
    Gs25Icon,

    LerboralioIcon,
    LhamourIcon,
    McsIcon,
    MobicomIcon,
    MonkondiIcon,
    MonosIcon,
    MonpelletsIcon,
    NominIcon,

    SharDoctorIcon,
    SodonFoodsIcon,
    SuuIcon,
    TavinUsIcon,
    TesoIcon,
    TodOimsIcon,

    UguujIcon,
    UnitelIcon,
    UrganaIcon,
    VitafitIcon



    // ApuIcon, ArvainUndesIcon, BaigalIcon,
    // CuIcon,    EnhiinshuuderIcon,    Gs25Icon,    GobiIcon,    GreenGroupIcon,    LhamourIcon,    MonkondiIcon,    McsIcon,    MonosIcon,
    // MonenzimIcon,    NominIcon,    OgoojIcon,    SodonFoodsIcon,    TavinUsIcon,    TodOimsIcon,    Vitafitcon

]);

    useEffect(() => {
        if (slideDone) {
            setSlideDone(false);
            setTimeID(
                setTimeout(() => {
                    slideNext();
                    setSlideDone(true);
                }, 3000)
            );
        }
        return () => clearTimeout(timeID);
    }, [slideDone]);

    const slideNext = () => {
        const container = sliderRef.current;
        if (container) {
            let o = container.scrollLeft += scrollAmount;
            //console.log(o);
            setLastPos(o)
            if (o === lastPos) {
                let p = container.scrollLeft -= lastPos;
                setLastPos(p)
            }
        }
    };

    const slidePrev = () => {
        const container = sliderRef.current;
        let o= container.scrollLeft -= scrollAmount; // Scroll left by the specified amount
        //setLastPos(o)
        // setActiveIndex((val) => {
        //     if (val <= 0) {
        //         return children.length - 1;
        //     } else {
        //         return val - 1;
        //     }
        // });
        return () => {
            sliderRef.current = null;
        }
    };

    const AutoPlayStop = () => {
        if (timeID > 0) {
            clearTimeout(timeID);
            setSlideDone(false);
        }
    };

    const AutoPlayStart = () => {
        if (!slideDone) {
            setSlideDone(true);
        }
    };

    return (

        // <div
        //     className="container__slider"
        //     onMouseEnter={AutoPlayStop}
        //     onMouseLeave={AutoPlayStart}
        // >

        <div>
            <div className="container">

                <footer>
                    <div style={{overflow: "hidden", justifyContent: "space-around"}}>
                        <h4 style={{fontWeight: "600"}}>Бидний Харилцагчид: </h4>
                        <div className="images-outer-container">
                        <button
                            className="nav-btn"
                            onClick={() => slidePrev()}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </button>

                    <div className="images-container" ref={sliderRef}>
                        {images.map((image, index) => {
                            return (
                                <img
                                    key={index}
                                    className="slider-image"
                                    alt="sliderImage"
                                    src={image}
                                />
                            );
                        })}
                    </div>
                        <button
                            className="nav-btn"
                            onClick={() => {
                                const container = sliderRef.current;
                                container.scrollLeft += scrollAmount; // Scroll right by the specified amount
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                        </button>
                        </div>
                    </div>
        </footer>



</div>
        </div>

    )
}
export default FooterPartners

