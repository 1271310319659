import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link, useLocation} from "@reach/router";
import Navigation from "../components/navigation";
import HeaderTop from "../components/headerTop";
import {Carousel} from "react-responsive-carousel";
import CheckDigitCalculatorIcon from "../img/check_digit_calculator_icon.png";
import QAndAIcon from "../img/q&a_icon.png";
import VerifiedIcon from "../img/verified_icon.png";
import AdvertisementIcon from "../img/advertisement_icon.png";
import PhotoIcon from "../img/photo_icon.png";
import AppIcon from "../img/app_icon.png";
import FooterList from "../components/footerList";
import FooterPartners from "../components/FooterPartners";
import FooterGlobal from "../components/footerGlobal";
import CustomCarousel from "../components/CustomCarousel";
import classnames from "classnames";

import he from "he";
import "../styles/sticky-social.css"
import "../styles/custom-carousel.css"
import "../styles/main.css"

import clientConfig from "../client-config";
import axios from "axios";


const DrawHtml = ({html}) => (
    <div className="large"
         dangerouslySetInnerHTML={{__html: "<div>" + html + "</div>"}}
    />
);

export default () => {
    const [loading, setLoading] = useState(false);
    const [posts2, setPosts2] = useState([]);
    const [news2, setNews2] = useState([]);
    const [events2, setEvents2] = useState([]);
    const [mainItem, setMainItem] = useState({});
    const [error, setError] = useState("");
    const location = useLocation();
    const wordPressSiteURL = clientConfig.siteUrl;

    const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/carousel?content=false&per_page=10`;
    const urlNews = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/news?content=false&media=false&per_page=2`;
    const urlEvents = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/events?content=false&media=false&per_page=2`;
    const urlMain = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/main?content=false&per_page=1`;
    const url2 = `${wordPressSiteURL}/wp-json/wp/v2/posts`
    useEffect(() => {
        setLoading(true);
        axios.get(url)
            .then(res => {
                if (res.data.length) {
                    setLoading(false);
                    setPosts2(res.data)
                } else {
                    setLoading(false);
                    setError("No news found");
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            });


        // axios.get(urlNews)
        //     .then(res => {
        //         if (res.data.length) {
        //             setLoading(false);
        //             setNews2(res.data)
        //         } else {
        //             setLoading(false);
        //             setError("No news found");
        //         }
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err.message);
        //     });

        // axios.get(urlEvents)
        //     .then(res => {
        //         if (res.data.length) {
        //             setLoading(false);
        //             setEvents2(res.data)
        //         } else {
        //             setLoading(false);
        //             setError("No events found");
        //         }
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err.message);
        //     });

        // axios.get(urlMain)
        //     .then(res => {
        //         if (res.data.length) {
        //             setLoading(false);
        //             setMainItem(res.data[0])
        //         } else {
        //             setLoading(false);
        //             setError("No main found");
        //         }
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err.message);
        //     });

    }, []);

    const createMarkup = (data) => ({
        __html: data
    });


    const regex = /(<([^>]+)>)/ig;

    return (

        <div className="toolkit">
            <div className="icon-bar-sticky-social hidden-mobile">
                <a href="https://www.facebook.com/GS1Mongolia" className="facebook">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                         className="bi bi-facebook" viewBox="0 0 16 16">
                        <path
                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                    </svg>
                </a>
                <a href="https://twitter.com/MongoliaGs1" className="twitter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                         className="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path
                            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                </a>
                <a href="https://www.youtube.com/channel/UClFCV8tfts6DueiJzW7IqYQ" className="youtube">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                         className="bi bi-youtube" viewBox="0 0 16 16">
                        <path
                            d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                </a>
            </div>

            <header>
                <HeaderTop/>
                <Navigation apageprop={location.pathname}/>
            </header>
            <div className="custom-carousel-container">
                <CustomCarousel>
                    {posts2.map((node, index) => {
                        const title = node.title || node.slug
                        return (
                            <div className="row" key={index}>
                                <div className="col-lg-8 col-md-12">
                                    <div style={{overflow:"hidden"}}>
                                    <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}>
                                        <img key={index} src={node.media.large} alt="ff" className="w-100 object-fit-md-contain"/>
                                    </Link>
                                    </div>
                                </div>

                                <div className="col-lg-4  col-md-12" >
                                    <div style={{margin:"10px", padding:"20px"}}>
                                        <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}>
                                            <h1>
                                                {he.decode(title)}
                                            </h1>
                                        </Link>
                                        <DrawHtml html={node.excerpt}/>
                                        <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}
                                              className="btn btn-primary bg-blue"
                                        >
                                            Дэлгэрэнгүй
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        );
                    })}
                </CustomCarousel>
            </div>
            {/*<div className="full-width" style={{height: "400px"}}>*/}
            {/*    {posts2.length ? (*/}
            {/*        <Carousel*/}
            {/*            infiniteLoop={true}*/}
            {/*            autoPlay={true}*/}
            {/*            useKeyboardArrows={true}*/}
            {/*            showArrows={true}*/}
            {/*            interval={3500}*/}
            {/*            transitionTime={500}*/}
            {/*            centerSlidePercentage={100}*/}
            {/*            showThumbs={false}*/}
            {/*            showStatus={false}*/}
            {/*        >*/}

            {/*            {posts2.map((node, index) => {*/}
            {/*                const title = node.title || node.slug*/}
            {/*                let btnClass = 'item active';*/}
            {/*                btnClass += " " + index;*/}
            {/*                if (index === 0 || index % 5 === 0) {*/}
            {/*                    btnClass += ' bg-purple';*/}
            {/*                } else if (index === 1 || index % 5 === 1) {*/}
            {/*                    btnClass += ' bg-lime';*/}
            {/*                } else if (index === 2 || index % 5 === 2) {*/}
            {/*                    btnClass += ' bg-orange';*/}
            {/*                } else if (index === 3 || index % 5 === 3) {*/}
            {/*                    btnClass += ' bg-sky';*/}
            {/*                } else if (index === 4 || index % 5 === 4) {*/}
            {/*                    btnClass += ' bg-grass';*/}
            {/*                }*/}
            {/*                return (*/}
            {/*                    <div key={node.slug} className="carousel-inner" style={{textAlign: "left"}}>*/}

            {/*                        <div className={btnClass}>*/}
            {/*                            <div className="container">*/}
            {/*                                <div className="row">*/}
            {/*                                    <div className="col-8">*/}
            {/*                                        {node.media &&*/}
            {/*                                            <img className="pull-left"*/}
            {/*                                                 style={{*/}
            {/*                                                     width: "300px",*/}
            {/*                                                     minHeight: "200px",*/}
            {/*                                                     marginLeft: "60px",*/}
            {/*                                                     objectFit: "cover",*/}
            {/*                                                     margin: "0 30px 30px 0"*/}
            {/*                                                 }}*/}
            {/*                                                 src={node.media.medium}/>*/}
            {/*                                        }*/}
            {/*                                    </div>*/}
            {/*                                    <div className="col-4">*/}
            {/*                                        <h1>*/}
            {/*                                            {he.decode(title)}*/}
            {/*                                        </h1>*/}
            {/*                                        <DrawHtml html={node.excerpt}/>*/}

            {/*                                        <Link to={"/news/news/p/" + decodeURIComponent(`${node.slug}/`)}*/}
            {/*                                              className="btn btn-primary bg-blue"*/}
            {/*                                        >*/}
            {/*                                            Дэлгэрэнгүй*/}
            {/*                                        </Link>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}

            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                );*/}
            {/*            })}*/}
            {/*        </Carousel>*/}
            {/*    ) : ''}*/}
            {/*</div>*/}
            <div className="bg-light-gray">
                <div className="container">
                    <section>
                        <div className="row">

                            <div className="col-md-8 col-md-offset-2">
                                <div style={{ marginTop:"10px", padding:"10px", textAlign:"center"}}>
                                    <a
                                        href="https://www.youtube.com/watch?v=viQQRfYnuqM&t=1s"
                                        role="button"
                                    >
                                        <span className="text1" >
                                        Шинээр баркод авах заавар
                                        </span>
                                    </a>
                                    </div>
                                <div style={{ marginTop:"10px", padding:"0px", textAlign:"center"}}>
                                    <span className="text2" >
                                    Нэг баркод сая сая боломж...
                                    </span>
                                </div>

                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1">
                                <div style={{backgroundColor: "#002C6C", marginTop:"10px", padding:"20px", textAlign:"center"}}>
                                    <span className="text" >GS1-ийн ач холбогдол, үнэ цэнэ</span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="bg-light-gray">
                <div className="container">
                    <section>
                        <div className="row">

                            <div className="col-md-6 col-md-offset-3">

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="content-module-container">

                                            <div className="content-module-featured-news" >
                                                <Link to="/main/main_app"
                                                      className="text-center color-blue color-white-hover"
                                                      role="button">
                                                <img src={AppIcon} alt="App image" style={{paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px", paddingBottom: "20px",}}/>

                                                    <p className="text-center color-blue">
                                                        GS1 Mongolia APP
                                                    </p><br/>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="content-module-container">

                                            <div className="content-module-featured-news">
                                                <Link to="/main/main_adv"
                                                      className="text-center color-blue color-white-hover"
                                                      role="button">
                                                <img src={AdvertisementIcon} alt="Advertisement image"
                                                     style={{paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px", paddingBottom: "20px",}}/>
                                                    <p className="text-center color-blue">Сурталчилгааны боломж</p>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="content-module-container">

                                            <div className="content-module-featured-news">
                                                <Link to="/main/main_photo"
                                                      className="text-center color-blue color-white-hover"
                                                      role="button">
                                                <img src={PhotoIcon} alt="Photo icon"
                                                     style={{paddingTop: "40px", paddingLeft: "40px", paddingRight: "40px", paddingBottom: "20px",}}/>
                                                    <p className="text-center color-blue">Зураг авах</p><br/>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="row">

                            <div className="col-md-8">


                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content-module-container">

                                            <div className="content-module-featured-news">
                                                <img src={CheckDigitCalculatorIcon} alt="Barcode"/>
                                                <div className="bg-white">
                                                    <h4 className="text-center">
                                                        Хяналтын оронг тооцох
                                                    </h4>
                                                    <a
                                                        href="https://www.gs1.org/services/check-digit-calculator"
                                                        className="btn-secondary" role="button"
                                                    >
                                                    <span className="color-blue">
                                                        Check digit calculator.</span><br/><br/>
                                                        Хяналтын оронг тооцох тухай
                                                    </a><br/><br/><br/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="content-module-container">

                                            <div className="content-module-featured-news">
                                                <img src={VerifiedIcon} alt="VerifiedBy" className=""/>
                                                <div  className="bg-white">
                                                    <h4 className="text-center">VERIFIED BY GS1</h4>
                                                    <a
                                                        href="https://www.gs1.org/services/verified-by-gs1"
                                                        className="btn-secondary" role="button"
                                                    >
                                                        <p className="color-blue">GS1-ээр баталгаажсан гэдэг нь </p>
                                                        <p className="color-orange">"Энэ миний бодож байсан
                                                            бүтээгдэхүүн мөн үү ?"</p> <p
                                                        className="color-blue">гэсэн асуултанд хариулах болно.</p>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-4">
                                <div className="content-module-container">
                                    <div className="content-module-featured-news">
                                        <img className="img-responsive" src={QAndAIcon} alt="Use"/>
                                        <div  className="bg-white">
                                            <h4>Асуулт/Хариулт</h4>
                                            <div className="btn-secondary">
                                            <ul>
                                                <li><Link to="/asuult/gs1-систем">
                                                    GS1 систем
                                                </Link></li>
                                                <li><Link to="/asuult/ecom-гэж-юу-вэ">
                                                    eCom гэж юу вэ ?
                                                </Link></li>
                                                <li><Link to="/asuult/epc-rfid">
                                                    EPC & RFID
                                                </Link></li>
                                                <li><Link to="/asuult/тээвэр-логистик">
                                                    Тээвэр логистик
                                                </Link></li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>

            <FooterGlobal/>
            <FooterPartners/>
            <FooterList/>

        </div>
    )
}
